<template>
  <b-carousel
    :id="id"
    v-model="slide"
    :interval="interval"
    controls
    indicators
    :background="background"
    :img-width="imageWidth"
    :img-height="imageHeight"
    style="text-shadow: 1px 1px 2px #333;"
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
  >
    <component
      v-for="(slide, key) in slides"
      v-bind:key="key"
      v-bind:is="slide.name"
      v-bind="slide.props"
    ></component>
  </b-carousel>
</template>

<script>
import SlideWithCaptionAndText from "@/components/slides/SlideWithCaptionAndText";
import SlideWithCustomTextAndImage from "@/components/slides/SlideWithCustomTextAndImage";
import SlideWithImageAndRightText from "@/components/slides/SlideWithImageAndRightText";
import SlideWithImageTemplate from "@/components/slides/SlideWithImageTemplate";
import SlideWithTextAndImage from "@/components/slides/SlideWithTextAndImage";

export default {
  name: "Carousel",
  components: {
    SlideWithCaptionAndText,
    SlideWithCustomTextAndImage,
    SlideWithImageAndRightText,
    SlideWithImageTemplate,
    SlideWithTextAndImage,
  },
  props: {
    id: {
      type: String,
      default: "carousel",
    },
    interval: {
      type: Number,
      default: 5000,
    },
    background: {
      type: String,
      default: "var(--secondary)",
    },
    imageWidth: {
      type: [Number, String],
      default: 1024,
    },
    imageHeight: {
      type: [Number, String],
      default: 480,
    },
    slides: {
      type: Array,
      default: () => [
        {
          name: "SlideWithTextAndImage",
        },
        { name: "SlideWithCustomTextAndImage" },
        {
          name: "SlideWithCustomTextAndImage",
          props: {
            text: null,
            image: "https://picsum.photos/1024/480/?image=58",
          },
        },
        { name: "SlideWithImageTemplate" },
        { name: "SlideWithCaptionAndText" },
      ],
    },
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onSlideStart(slide) {
      this.sliding = true;
    },
    // eslint-disable-next-line no-unused-vars
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>
