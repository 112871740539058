<template>
  <!-- Slide with (optional) custom text and image -->
  <b-carousel-slide :img-src="image">
    <h1 v-if="text">{{ text }}</h1>
  </b-carousel-slide>
</template>

<script>
export default {
  name: "SlideWithCustomTextAndImage",
  props: {
    text: {
      type: String,
      default: "Overlay Text",
    },
    image: {
      type: String,
      default: "https://picsum.photos/1024/480/?image=54",
    },
  },
};
</script>
