var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-carousel-slide", {
    scopedSlots: _vm._u([
      {
        key: "img",
        fn: function() {
          return [
            _c("img", {
              staticClass: "d-block img-fluid w-100",
              attrs: {
                width: _vm.width,
                height: _vm.height,
                src: _vm.image,
                alt: _vm.caption
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }