var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-carousel-slide", { attrs: { "img-src": _vm.image } }, [
    _vm.text ? _c("h1", [_vm._v(_vm._s(_vm.text))]) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }