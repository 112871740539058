var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-carousel-slide", {
    attrs: { caption: _vm.caption, text: _vm.text, "img-src": _vm.image }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }