<template>
  <!-- Slide with image and text on the right -->
  <b-carousel-slide :img-src="image">
    <template #img>
      <div class="row">
        <div class="col-md-4">
          <img
            class="d-block"
            :width="width"
            :height="height"
            :src="image"
            :alt="caption"
          />
        </div>
        <div class="col-md-6 text-white m-auto pt-2 pt-md-0 px-4 px-md-0">
          <h3>{{ caption }}</h3>
          <p>{{ text }}</p>
        </div>
      </div>
    </template>
  </b-carousel-slide>
</template>

<script>
export default {
  name: "SlideWithImageAndRightText",
  props: {
    caption: {
      type: String,
      default: "Slide caption",
    },
    text: {
      type: String,
      default: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
    },
    image: {
      type: String,
      default: "https://picsum.photos/1024/480/?image=52",
    },
    width: {
      type: [Number, String],
      default: "auto",
    },
    height: {
      type: [Number, String],
      default: 250,
    },
  },
};
</script>
