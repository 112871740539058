var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", { staticClass: "text-primary" }, [
        _vm._v(_vm._s(_vm.$t("services.request")))
      ]),
      _c(
        "b-form",
        {
          on: {
            submit: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.onChildSubmit($event)
            }
          }
        },
        [
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "service-input-group",
                        label: _vm.$t("services.service"),
                        "label-for": "service"
                      }
                    },
                    [
                      _c(
                        "multiselect",
                        {
                          class: { "is-invalid": _vm.$v.form.service.$error },
                          attrs: {
                            id: "service",
                            "close-on-select": true,
                            "clear-on-select": false,
                            "aria-autocomplete": false,
                            multiple: false,
                            placeholder: _vm.$t("select_placeholder"),
                            "select-label": _vm.$t("multiselect.selectLabel"),
                            "selected-label": _vm.$t(
                              "multiselect.selectedLabel"
                            ),
                            "deselect-label": _vm.$t(
                              "multiselect.deselectLabel"
                            ),
                            "track-by": "id",
                            options: _vm.services,
                            state: _vm.validateState("service"),
                            "aria-describedby": "service-live-feedback"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function(ref) {
                                var option = ref.option
                                return [_vm._v(" " + _vm._s(option.name) + " ")]
                              }
                            },
                            {
                              key: "singleLabel",
                              fn: function(ref) {
                                var option = ref.option
                                return [_vm._v(" " + _vm._s(option.name) + " ")]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.$v.form.service.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.form.service, "$model", $$v)
                            },
                            expression: "$v.form.service.$model"
                          }
                        },
                        [
                          _c("template", { slot: "noOptions" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("multiselect.noOptionsLabel")) +
                                " "
                            )
                          ]),
                          _c("template", { slot: "noResult" }, [
                            _vm._v(
                              _vm._s(_vm.$t("multiselect.noResultLabel")) + " "
                            )
                          ])
                        ],
                        2
                      ),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "service-live-feedback" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("validation.required")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c(
                "b-button",
                { attrs: { type: "submit", variant: "success" } },
                [_vm._v(_vm._s(_vm.$t("buttons.service_request")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }