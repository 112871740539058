var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-carousel-slide",
    {
      attrs: { caption: _vm.caption, "img-blank": "", "img-alt": _vm.caption }
    },
    [_c("p", [_vm._v(" " + _vm._s(_vm.text) + " ")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }