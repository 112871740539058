var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-carousel",
    {
      staticStyle: { "text-shadow": "1px 1px 2px #333" },
      attrs: {
        id: _vm.id,
        interval: _vm.interval,
        controls: "",
        indicators: "",
        background: _vm.background,
        "img-width": _vm.imageWidth,
        "img-height": _vm.imageHeight
      },
      on: { "sliding-start": _vm.onSlideStart, "sliding-end": _vm.onSlideEnd },
      model: {
        value: _vm.slide,
        callback: function($$v) {
          _vm.slide = $$v
        },
        expression: "slide"
      }
    },
    _vm._l(_vm.slides, function(slide, key) {
      return _c(
        slide.name,
        _vm._b({ key: key, tag: "component" }, "component", slide.props, false)
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }