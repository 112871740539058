<template>
  <!-- Slide with text and image -->
  <b-carousel-slide
    :caption="caption"
    :text="text"
    :img-src="image"
  ></b-carousel-slide>
</template>

<script>
export default {
  name: "SlideWithTextAndImage",
  props: {
    caption: {
      type: String,
      default: "Slide caption",
    },
    text: {
      type: String,
      default: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
    },
    image: {
      type: String,
      default: "https://picsum.photos/1024/480/?image=52",
    },
  },
};
</script>
