<template>
  <Carousel :slides="slides" :image-height="250" />
</template>

<script>
import Carousel from "@/components/Carousel";

export default {
  name: "ServicesCarousel",
  props: {
    services: {
      type: Array,
      required: true,
    },
  },
  components: {
    Carousel,
  },
  data() {
    return {
      slides: this.services.map(function (service) {
        return {
          name: "SlideWithImageAndRightText",
          props: {
            caption: service.name,
            text: service.description,
            image: service.image,
          },
        };
      }),
    };
  },
};
</script>
