<template>
  <!-- Slide with blank fluid image to maintain slide aspect ratio -->
  <b-carousel-slide :caption="caption" img-blank :img-alt="caption">
    <p>
      {{ text }}
    </p>
  </b-carousel-slide>
</template>

<script>
export default {
  name: "SlideWithCaptionAndText",
  props: {
    caption: {
      type: String,
      default: "Blank image caption",
    },
    text: {
      type: String,
      default:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse\n" +
        "eros felis, tincidunt a tincidunt eget, convallis vel est. Ut\n" +
        "pellentesque ut lacus vel interdum.",
    },
  },
};
</script>
