var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-carousel-slide", {
    attrs: { "img-src": _vm.image },
    scopedSlots: _vm._u([
      {
        key: "img",
        fn: function() {
          return [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-4" }, [
                _c("img", {
                  staticClass: "d-block",
                  attrs: {
                    width: _vm.width,
                    height: _vm.height,
                    src: _vm.image,
                    alt: _vm.caption
                  }
                })
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "col-md-6 text-white m-auto pt-2 pt-md-0 px-4 px-md-0"
                },
                [
                  _c("h3", [_vm._v(_vm._s(_vm.caption))]),
                  _c("p", [_vm._v(_vm._s(_vm.text))])
                ]
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }