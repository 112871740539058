<template>
  <div>
    <h4 class="text-primary">{{ $t("services.request") }}</h4>
    <b-form @submit.stop.prevent="onChildSubmit">
      <!-- Service -->
      <b-form-row>
        <b-col lg="12">
          <b-form-group
            id="service-input-group"
            :label="$t('services.service')"
            label-for="service"
          >
            <multiselect
              id="service"
              :class="{ 'is-invalid': $v.form.service.$error }"
              :close-on-select="true"
              :clear-on-select="false"
              :aria-autocomplete="false"
              :multiple="false"
              :placeholder="$t('select_placeholder')"
              :select-label="$t('multiselect.selectLabel')"
              :selected-label="$t('multiselect.selectedLabel')"
              :deselect-label="$t('multiselect.deselectLabel')"
              track-by="id"
              :options="services"
              v-model="$v.form.service.$model"
              :state="validateState('service')"
              aria-describedby="service-live-feedback"
            >
              <template slot="noOptions">
                {{ $t("multiselect.noOptionsLabel") }}
              </template>
              <template slot="noResult"
                >{{ $t("multiselect.noResultLabel") }}
              </template>

              <template slot="option" slot-scope="{ option }">
                {{ option.name }}
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.name }}
              </template>
            </multiselect>
            <b-form-invalid-feedback id="service-live-feedback">
              {{ $t("validation.required") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <!-- City -->
      <!-- <b-form-row>
        <b-col lg="12">
          <b-form-group
            id="city-input-group"
            :label="$t('register.city')"
            label-for="city"
          >
            <multiselect
              id="city"
              :class="{ 'is-invalid': $v.form.city.$error }"
              :close-on-select="true"
              :clear-on-select="false"
              :multiple="false"
              :placeholder="$t('select_placeholder')"
              :select-label="$t('multiselect.selectLabel')"
              :selected-label="$t('multiselect.selectedLabel')"
              :deselect-label="$t('multiselect.deselectLabel')"
              label="comune"
              track-by="id"
              :options="cities"
              v-model="$v.form.city.$model"
              :state="validateState('city')"
              aria-describedby="city-live-feedback"
            >
              <template slot="option" slot-scope="{ option }">
                {{ option.comune }} ({{ option.province }})
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.comune }} ({{ option.province }})
              </template>

              <template slot="noOptions">
                {{ $t("multiselect.noOptionsLabel") }}
              </template>
              <template slot="noResult"
                >{{ $t("multiselect.noResultLabel") }}
              </template>
            </multiselect>
            <b-form-invalid-feedback id="city-live-feedback">
              {{ $t("validation.required") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row> -->
      <!-- Actions -->
      <div class="text-right">
        <b-button type="submit" variant="success"
          >{{ $t("buttons.service_request") }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import { fields } from "@/services/endpoints";

export default {
  mixins: [validationMixin],
  name: "RequestServiceSearchForm",
  props: {
    services: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      form: this.getDefaultFormData(),
      fields: fields,
    };
  },
  computed: {
    ...mapGetters({
      formErrors: "account/getFormErrors",
      cities: "cities/cities",
    }),
  },
  validations: {
    form: {
      service: {
        required,
      },
      // city: {
      //   required,
      // },
    },
  },
  methods: {
    ...mapActions({
      clearRegistration: "account/clearRegistration",
      getCities: "cities/getCities",
    }),
    getDefaultFormData() {
      return {
        service: null,
        city: null,
      };
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onChildSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      router.push({
        name: "request_service_affiliate",
        params: { service: this.form.service},
        // params: { service: this.form.service, city: this.form.city },
      });
    },
    resetForm() {
      this.form = this.getDefaultFormData();
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  mounted() {
    if (!this.cities || !this.cities.length) {
      // Load from API
      console.log("Loading cities from API");
      this.getCities();
    }
  },
};
</script>
