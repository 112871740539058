import { render, staticRenderFns } from "./SlideWithImageAndRightText.vue?vue&type=template&id=64a3d103&"
import script from "./SlideWithImageAndRightText.vue?vue&type=script&lang=js&"
export * from "./SlideWithImageAndRightText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\portale15780570A\\Desktop\\MyCare_CONTAINER\\MyCare2-FE\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64a3d103')) {
      api.createRecord('64a3d103', component.options)
    } else {
      api.reload('64a3d103', component.options)
    }
    module.hot.accept("./SlideWithImageAndRightText.vue?vue&type=template&id=64a3d103&", function () {
      api.rerender('64a3d103', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/slides/SlideWithImageAndRightText.vue"
export default component.exports