<template>
  <!-- Slide with img slot -->
  <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
  <b-carousel-slide>
    <template #img>
      <img
        class="d-block img-fluid w-100"
        :width="width"
        :height="height"
        :src="image"
        :alt="caption"
      />
    </template>
  </b-carousel-slide>
</template>

<script>
export default {
  name: "SlideWithImageTemplate",
  props: {
    caption: {
      type: String,
      default: "Slide caption",
    },
    image: {
      type: String,
      default: "https://picsum.photos/1024/480/?image=55",
    },
    width: {
      type: Number,
      default: 1024,
    },
    height: {
      type: Number,
      default: 480,
    },
  },
};
</script>
